import React from "react";

const services = [
  {
    title: "Full Stack Development",
    description:
      "We built complete software solutions that work both on the front-end (what users see) and back-end (how things work behind the scenes). This helps businesses run more smoothly and efficiently.",
  },
  {
    title: "Mobile App Development",
    description:
      "We created easy-to-use mobile apps that help businesses connect with their customers. These apps are designed to be fast, reliable, and simple to use.",
  },
  {
    title: "AI Solutions",
    description:
      "We developed smart technology that uses artificial intelligence to help businesses make better decisions. This includes creating custom programs that analyze data and provide useful insights.",
  },
  {
    title: "Graphic and Web Design",
    description:
      "Our team designed eye-catching graphics and modern websites. We focus on making sure the designs look great and are easy for users to navigate.",
  },
  {
    title: "Cybersecurity",
    description:
      "We set up strong security measures to protect businesses from cyber threats. This includes securing networks, protecting data, and preventing potential attacks.",
  },
  {
    title: "Cloud Solutions",
    description:
      "We provided cloud-based services that help businesses store and manage their data online. This makes it easier to access information and reduces costs.",
  },
  {
    title: "CRM Software Development",
    description:
      "We created custom CRM systems that help businesses manage their customer relationships and improve their operations. These systems include tools for tracking and analyzing customer interactions.",
  },
  {
    title: "Digital Marketing Campaigns",
    description:
      "We planned and ran marketing campaigns to boost businesses’ online presence. This includes improving search engine rankings, managing social media, and creating engaging content.",
  },
  {
    title: "IoT Solutions",
    description:
      "We developed Internet of Things (IoT) solutions that allow businesses to collect and use data from connected devices. This helps them monitor and improve their operations in real-time.",
  },
  {
    title: "E-Commerce Development",
    description:
      "We created easy-to-use online stores that help businesses sell their products and services. Our solutions include safe payment methods and a smooth shopping experience.",
  },
  {
    title: "API Integration",
    description:
      "We connected different software systems so they can work together better. This helps businesses automate tasks and make their apps and services communicate more easily.",
  },
  {
    title: "Data Analytics",
    description:
      "We helped businesses understand their data by providing clear reports. This helps them track progress, spot trends, and make smart decisions to grow.",
  },
];

const Work = () => {
  return (
    <>
      <div className="relative isolate overflow-hidden bg-gray-900 py-24 sm:py-32">
        <img
          src="https://images.unsplash.com/photo-1521737604893-d14cc237f11d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-y=.8&w=2830&h=1500&q=80&blend=111827&sat=-100&exp=15&blend-mode=multiply"
          alt
          className="absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center"
        />
        <div
          className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
          aria-hidden="true"
        >
          <div
            className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
        <div
          className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
          aria-hidden="true"
        >
          <div
            className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto lg:mx-0">
            <h2 className="text-4xl font-bold tracking-tight text-white sm:text-5xl text-center">
              Works
            </h2>
            <p className="mt-0 text-lg  leading-8 text-gray-300 text-center px-16">
              See how simple it is to shop with us and check out our easy steps
              for a smooth experience.
            </p>
          </div>
          {/* <div className="mx-auto mt-10 max-w-2xl lg:mx-0 lg:max-w-none">
                        <div className="grid grid-cols-1 gap-x-8 gap-y-6 text-base font-semibold leading-7 text-white sm:grid-cols-2 md:flex lg:gap-x-10">
                            <a href="#">Open roles <span aria-hidden="true">→</span></a>
                            <a href="#">Internship program <span aria-hidden="true">→</span></a>
                            <a href="#">Our values <span aria-hidden="true">→</span></a>
                            <a href="#">Meet our leadership <span aria-hidden="true">→</span></a>
                        </div>
                        <dl className="mt-16 grid grid-cols-1 gap-8 sm:mt-20 sm:grid-cols-2 lg:grid-cols-4">
                            <div className="flex flex-col-reverse">
                                <dt className="text-base leading-7 text-gray-300">Offices worldwide</dt>
                                <dd className="text-2xl font-bold leading-9 tracking-tight text-white">12</dd>
                            </div>
                            <div className="flex flex-col-reverse">
                                <dt className="text-base leading-7 text-gray-300">Full-time colleagues</dt>
                                <dd className="text-2xl font-bold leading-9 tracking-tight text-white">300+</dd>
                            </div>
                            <div className="flex flex-col-reverse">
                                <dt className="text-base leading-7 text-gray-300">Hours per week</dt>
                                <dd className="text-2xl font-bold leading-9 tracking-tight text-white">40</dd>
                            </div>
                            <div className="flex flex-col-reverse">
                                <dt className="text-base leading-7 text-gray-300">Paid time off</dt>
                                <dd className="text-2xl font-bold leading-9 tracking-tight text-white">Unlimited</dd>
                            </div>
                        </dl>
                    </div> */}
        </div>
      </div>
      <div className="min-h-screen bg-gray-100 py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-extrabold text-gray-900 text-center mb-12">
            Step-by-Step Guide
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            {services.map((step, index) => (
              <div key={index} className="bg-white shadow-md rounded-lg p-6">
                <h3 className="text-xl font-semibold text-gray-900 mb-2">
                  {index + 1}. {step.title}
                </h3>
                <ul className="pl-3 text-justify">
                  <li className="text-gray-700 list-disc">
                    {step.description}
                  </li>
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
export default Work;
