// userSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {BASEURL} from '../baseurl'
export const AddTicket = createAsyncThunk(
  "user/AddTicket",
  async (values, thunkAPI) => {
    try {
      // Your asynchronous logic to authenticate user here
      const response = await fetch(`${BASEURL}/api/v1/ticket/add`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      // Handle error
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
export const getTickets = createAsyncThunk(
  "csPro/getTickets",
  async (registration, thunkAPI) => {
    try {
      const response = await fetch(`${BASEURL}/api/v1/ticket/registration/${registration}`);

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const data = await response.json();
      console.log(data);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
export const fetchTicketDetails = createAsyncThunk(
  "csPro/fetchTicketDetails",
  async (ticket_no, thunkAPI) => {
    try {
      const response = await fetch(`${BASEURL}/api/v1/ticket/${ticket_no}`);

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);


export const deleteTicket = createAsyncThunk(
  "csPro/deleteTicket",
  async (ticket_id, thunkAPI) => {
    try {
      // Your asynchronous logic to delete student here
      const response = await fetch(`${BASEURL}/api/v1/ticket/delete/${ticket_id}`, {
        method: "DELETE",
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }
      const data = await response.json();
      return { ticketId: ticket_id, message: data.message };
    } catch (error) {
      // Handle error
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
export const updateTicket = createAsyncThunk(
  "student/updateTicket",
  async ({ ticket_id, updatedData }, thunkAPI) => {
    try {
      // Your asynchronous logic to update student here
      const response = await fetch(`${BASEURL}/api/v1/ticket/update/${ticket_id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const data = await response.json();

      return data;
    } catch (error) {
      // Handle error
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const replyTicket = createAsyncThunk(
  "ticket/replyTicket",
  async ({ ticketId, replyData }, thunkAPI) => {
    try {
      const response = await fetch(`${BASEURL}/api/v1/ticket/reply/${ticketId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(replyData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const data = await response.json();
      return { message: data.message, ticketId };
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);


const initialState = {
    tickets: [],
//   singleProject: null,
//   Projectbycategory:null,
//   singlecompanyProject: null,
//   singlesellerProject: null,
  loading: false,
  error: null,
  message: null,
  ticket: null,

};

const TicketSlice = createSlice({
  name: "Ticket",
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(AddTicket.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(AddTicket.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.message = action.payload.message;
      })
      .addCase(AddTicket.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      }) 
      .addCase(getTickets.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getTickets.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.tickets = action.payload;
      })
      .addCase(getTickets.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })
      .addCase(fetchTicketDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTicketDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.ticket = action.payload.data;
      })
      .addCase(fetchTicketDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })

    //   .addCase(getTicketsbycategory.pending, (state) => {
    //     state.loading = true;
    //     state.error = null;
    //   })
    //   .addCase(getTicketsbycategory.fulfilled, (state, action) => {
    //     state.loading = false;
    //     state.error = null;
    //     state.Projectbycategory = action.payload.Projectbycategory;
    //   })
    //   .addCase(getTicketsbycategory.rejected, (state, action) => {
    //     state.loading = false;
    //     state.error = action.payload.error;
    //   })


    //   .addCase(getSingleProject.pending, (state) => {
    //     state.loading = true;
    //     state.error = null;
    //   })
    //   .addCase(getSingleProject.fulfilled, (state, action) => {
    //     state.loading = false;
    //     state.error = null;
    //     state.singleProject = action.payload.singleProject;
    //   })
    //   .addCase(getSingleProject.rejected, (state, action) => {
    //     state.loading = false;
    //     state.error = action.payload.error;
    //   })
    //   .addCase(getSingleCompanyProject.pending, (state) => {
    //     state.loading = true;
    //     state.error = null;
    //   })
    //   .addCase(getSingleCompanyProject.fulfilled, (state, action) => {
    //     state.loading = false;
    //     state.error = null;
    //     state.singlecompanyProject = action.payload.singlecompanyProject;
    //   })
    //   .addCase(getSingleCompanyProject.rejected, (state, action) => {
    //     state.loading = false;
    //     state.error = action.payload.error;
    //   })
    //   .addCase(getSingleSellerProject.pending, (state) => {
    //     state.loading = true;
    //     state.error = null;
    //   })
    //   .addCase(getSingleSellerProject.fulfilled, (state, action) => {
    //     state.loading = false;
    //     state.error = null;
    //     state.singlesellerProject = action.payload.singlesellerProject;
    //   })
    //   .addCase(getSingleSellerProject.rejected, (state, action) => {
    //     state.loading = false;
    //     state.error = action.payload.error;
    //   })
      .addCase(deleteTicket.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteTicket.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.message = action.payload.message;
        state.tickets = state.tickets.filter(
          (c) => c.ticketId !== action.payload.ticketId
        );
      })
      .addCase(deleteTicket.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })
      .addCase(updateTicket.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateTicket.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.message = action.payload.message;
      })
      .addCase(updateTicket.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })
      .addCase(replyTicket.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(replyTicket.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.message = action.payload.message;
        const ticketIndex = state.tickets.findIndex(ticket => ticket.id === action.payload.ticketId);
        if (ticketIndex !== -1) {
          state.tickets[ticketIndex].replies.push(action.payload.replyData); // Assuming replies are stored in the ticket object
        }
      })
      .addCase(replyTicket.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      });
  },
});

export const { clearErrors, clearMessage } = TicketSlice.actions;

export default TicketSlice.reducer;
