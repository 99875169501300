import React from 'react';

const careerData = [
  {
    title: "Why Join Us?",
    description: [
      "Be part of a forward-thinking company that is shaping the future of online shopping.",
      "We offer numerous opportunities for professional development and career advancement.",
      "Work in a team-oriented environment where your ideas and contributions are valued.",
      "We believe in maintaining a healthy work-life balance and offer flexible work arrangements."
    ]
  },
  {
    title: "Current Openings",
    description: "Look into opportunities to join our dynamic team and have an impact on the world of technology. Below are our current openings:",
    jobs: [
      {
        role: "Full Stack Developer",
        location: "Kurukshetra, Haryana, India",
        responsibilities: "Develop responsive web applications using modern technologies.",
        qualifications: "Proficiency in JavaScript, experience with Node.js and React."
      },
    
      {
        role: "UI/UX Designer",
        location: "Kurukshetra, Haryana, India",
        responsibilities: "Design intuitive user interfaces for web and mobile applications, conduct user research and testing.",
        qualifications: "Proficiency in UI/UX design tools such as Sketch, Adobe XD, or Figma, strong understanding of user-centered design principles."
      }
    ]
  },
  {
    title: "How to Apply",
    description: "At Cybersolvings, we've designed our hiring process to be thorough and efficient, ensuring we find the best talent to join our team. Here's what you can expect:",
    steps: [
      "Application Review: Our team carefully examines each application to evaluate qualifications and potential fit.",
      "Initial Phone Screening: A brief call to talk about your background, experience, and career goals.",
      "In-person Interviews: Meet with our team to discuss your skills further and how well you fit into our company culture.",
      "Technical Assessment: Show your technical skills through practical exercises related to the job.",
      "HR Round: A final discussion to address any remaining questions and talk about potential job details.",
      "Job Offer: If successful, we'll offer you a position to join the Cybersolvings team!"
    ]
  }
];

const Career = () => {
  return (
    <>
      <div className='w-full'>
        <div className='container mx-auto'>
          <section className="relative isolate overflow-hidden bg-white px-6 py-16 sm:py-16 lg:px-8">
            <div className="absolute inset-0 -z-10 bg-[radial-gradient(45rem_50rem_at_top,theme(colors.indigo.100),white)] opacity-20" />
            <div className="absolute inset-y-0 right-1/2 -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-66deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center" />
            <div className="mx-auto max-w-2xl lg:max-w-4xl">
              <h2 className='text-2xl font-bold text-center mb-0 text-black mb-0 sm:text-4xl'>
                Build Your Tech Career with Us
              </h2>
              <figure className="mt-0">
                <blockquote className="text-center text-xl font-semibold text-gray-900 sm:text-2xl">
                  <p className='text-lg mt-0 text-black-300 px-8 text-center mb-4'>
                    Join our team of creative IT experts and help shape the future of technology solutions.
                  </p>
                </blockquote>
              </figure>
            </div>
          </section>
        </div>
      </div>

      <div className="container mx-auto p-8">
        {careerData.map((section, index) => (
          <div className="bg-white shadow-sm border rounded-lg p-6 mb-12" key={index}>
            <h2 className="text-3xl font-semibold mb-6">{section.title}</h2>
            {section.description && (
              <p className="mb-6">{section.description}</p>
            )}
            {section.jobs && (
              <div className="space-y-8">
                {section.jobs.map((job, jobIndex) => (
                  <div key={jobIndex}>
                    <h3 className="text-2xl font-bold mb-2">{job.role}</h3>
                    <p><strong>Location:</strong> {job.location}</p>
                    <p><strong>Responsibilities:</strong> {job.responsibilities}</p>
                    <p><strong>Qualifications:</strong> {job.qualifications}</p>
                  </div>
                ))}
              </div>
            )}
            {section.steps && (
              <div className="space-y-4">
                {section.steps.map((step, stepIndex) => (
                  <p key={stepIndex}>{step}</p>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default Career;
