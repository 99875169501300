import React from "react";
import { Link } from "react-router-dom";
const Policy = () => {
  return (
    <>
      <div className="relative isolate overflow-hidden bg-gray-900 py-24 sm:py-32">
        <img
          src="https://images.unsplash.com/photo-1521737604893-d14cc237f11d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-y=.8&w=2830&h=1500&q=80&blend=111827&sat=-100&exp=15&blend-mode=multiply"
          alt
          className="absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center"
        />
        <div
          className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
          aria-hidden="true"
        >
          <div
            className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
        <div
          className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
          aria-hidden="true"
        >
          <div
            className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto lg:mx-0">
            <h2 className="text-4xl font-bold tracking-tight text-white sm:text-5xl text-center">
              Privacy Policy
            </h2>
            <p className="mt-0 text-lg  leading-8 text-gray-300 text-center px-16">
              We are committed to protecting your privacy and ensuring you have
              a positive experience on our website. This policy outlines our
              approach to collecting, using, and protecting the personal
              information you provide to us.
            </p>
          </div>
          {/* <div className="mx-auto mt-10 max-w-2xl lg:mx-0 lg:max-w-none">
                        <div className="grid grid-cols-1 gap-x-8 gap-y-6 text-base font-semibold leading-7 text-white sm:grid-cols-2 md:flex lg:gap-x-10">
                            <a href="#">Open roles <span aria-hidden="true">→</span></a>
                            <a href="#">Internship program <span aria-hidden="true">→</span></a>
                            <a href="#">Our values <span aria-hidden="true">→</span></a>
                            <a href="#">Meet our leadership <span aria-hidden="true">→</span></a>
                        </div>
                        <dl className="mt-16 grid grid-cols-1 gap-8 sm:mt-20 sm:grid-cols-2 lg:grid-cols-4">
                            <div className="flex flex-col-reverse">
                                <dt className="text-base leading-7 text-gray-300">Offices worldwide</dt>
                                <dd className="text-2xl font-bold leading-9 tracking-tight text-white">12</dd>
                            </div>
                            <div className="flex flex-col-reverse">
                                <dt className="text-base leading-7 text-gray-300">Full-time colleagues</dt>
                                <dd className="text-2xl font-bold leading-9 tracking-tight text-white">300+</dd>
                            </div>
                            <div className="flex flex-col-reverse">
                                <dt className="text-base leading-7 text-gray-300">Hours per week</dt>
                                <dd className="text-2xl font-bold leading-9 tracking-tight text-white">40</dd>
                            </div>
                            <div className="flex flex-col-reverse">
                                <dt className="text-base leading-7 text-gray-300">Paid time off</dt>
                                <dd className="text-2xl font-bold leading-9 tracking-tight text-white">Unlimited</dd>
                            </div>
                        </dl>
                    </div> */}
        </div>
      </div>
      <div className="bg-gray-100 min-h-screen py-12">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="bg-white shadow-md rounded-lg overflow-hidden">
            <div className="px-6 py-8">
              <div className="space-y-6">
                <div>
                  <h3 className="text-xl font-semibold text-gray-900 mb-1">
                    {" "}
                    Information We Collect
                  </h3>
                  <p className="text-gray-700 mb-4">
                    We may collect personal information that you provide
                    directly to us, such as
                  </p>
                  <div className="w-full ">
                    <div className="bg-white shadow-sm border rounded-lg p-6 mb-8">
                      {/* <h2 className="text-2xl font-semibold mb-4">Customer Support</h2> */}
                      {/* <p className="mb-4">For any questions about your orders, products, or our services, contact our customer support team.</p> */}
                      <p>
                        Email:{" "}
                        <Link
                          to="mailto:support@Cybersolvings.com"
                          className="text-blue-500"
                        >
                          info@cybersolvings.com
                        </Link>
                      </p>
                      <p>
                        Phone:{" "}
                        <Link to="tel:+1234567890" className="text-blue-500">
                        +91-80534-30300
                        </Link>
                      </p>
                      <p>
                        Company name:{" "}
                        <Link
                          to="mailto:support@Cybersolvings.com"
                          className="text-blue-500"
                        >
                          Cybersolvings.com
                        </Link>
                      </p>
                      {/* <p>Job title: <Link to="tel:+1234567890" className="text-blue-500">+1 234 567 890</Link></p> */}
                    </div>
                  </div>
                </div>
                <div>
                  <h3 className="text-lg font-semibold text-gray-900 mb-2">
                    Cookies and Tracking Technologies
                  </h3>
                  <p className="text-gray-700">
                    Our website may use cookies and similar tracking
                    technologies to enhance your browsing experience. You can
                    manage your cookie preferences through your browser
                    settings.
                  </p>
                </div>
                <div>
                  <h3 className="text-lg font-semibold text-gray-900 mb-2">
                    Third-Party Links
                  </h3>
                  <p className="text-gray-700">
                    Our website may contain links to third-party websites or
                    services that are not operated by us. We are not responsible
                    for the privacy practices or content of these third parties.
                  </p>
                </div>
                <div>
                  <h3 className="text-lg font-semibold text-gray-900 mb-2">
                    Your Rights
                  </h3>
                  <p className="text-gray-700">
                    You have certain rights regarding your personal information,
                    including the right to access, correct, or delete your data.
                    For inquiries about your rights or to exercise them, please
                    contact us using the information provided below.
                  </p>
                </div>
                <div>
                  <h3 className="text-lg font-semibold text-gray-900 mb-2">
                    {" "}
                    Changes to This Privacy Policy
                  </h3>
                  <p className="text-gray-700">
                    We may update this Privacy Policy from time to time to
                    reflect changes in our practices or legal requirements. We
                    encourage you to review this page periodically for the
                    latest information.
                  </p>
                </div>

                <div>
                  <h3 className="text-lg font-semibold text-gray-900 mb-2">
                    {" "}
                    Contact Us
                  </h3>
                  <p className="text-gray-700">
                    If you have any questions or concerns about this Privacy
                    Policy or our data practices, please contact us.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-8 text-center text-gray-700">
          <p>
            By using Cybersolvings, you agree to the terms of this Privacy
            Policy. Thank you for trusting Cybersolvings with your information!
          </p>
        </div>
      </div>
    </>
  );
};
export default Policy;
