import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTicketDetails, replyTicket } from '../redux/ticketSlice'; // Import your fetch and reply actions

const TicketDetails = () => {
  const { ticket_no } = useParams(); // Get ticket_no from route parameters
  const dispatch = useDispatch();

  // Fetch ticket details from the Redux store
  const { ticket, loading, error } = useSelector((state) => state.tickets);

  // State to handle the reply message
  const [replyMessage, setReplyMessage] = useState('');

  useEffect(() => {
    // Dispatch the action to fetch ticket details
    if (ticket_no) {
      dispatch(fetchTicketDetails(ticket_no));
    }
  }, [dispatch, ticket_no]);

  // Handle the reply submission
  const handleReplySubmit = (e) => {
    e.preventDefault();
    if (replyMessage.trim()) {
      dispatch(replyTicket({ ticket_no, response_text: replyMessage }));
      setReplyMessage(''); // Clear the input after submission
    }
  };

  // Render a loading spinner while data is being fetched
  if (loading) {
    return <div>Loading...</div>;
  }

  // Render an error message if there's an error fetching the data
  if (error) {
    return <div>Error: {error}</div>;
  }

  // If no ticket data is available
  if (!ticket || ticket.length === 0) {
    return <div>No ticket details found.</div>;
  }

  return (
    <div className="chat-container p-4 max-w-2xl mx-auto">
      <div className="ticket-info mb-4">
        <h2 className="text-2xl font-bold mb-2">Ticket Details</h2>
        <p><strong>Ticket No:</strong> {ticket[0]?.ticket_no}</p>
        <p><strong>Registration:</strong> {ticket[0]?.registration}</p>
        <p><strong>Assigned To:</strong> {ticket[0]?.ticket_to}</p>
        <p><strong>Status:</strong> {ticket[0]?.status}</p>
        <p><strong>Received Date:</strong> {ticket[0]?.recived_date}</p>
        <p><strong>Complete Date:</strong> {ticket[0]?.complete_date}</p>
        <p><strong>Reason:</strong> {ticket[0]?.reason}</p>
      </div>

      <div className="chat-box bg-gray-100 p-4 rounded-lg shadow-lg">
        <h3 className="text-lg font-bold mb-2">Responses</h3>
        {ticket.map((response) => (
          <div key={response.response_id} className="chat-message mb-3">
            <p className="text-sm text-gray-600 mb-1"><strong>Date:</strong> {new Date(response.response_date).toLocaleString()}</p>
            <p className="text-gray-800">{response.response_text}</p>
            <hr className="my-2" />
          </div>
        ))}
      </div>

      {/* Reply form */}
      <form onSubmit={handleReplySubmit} className="reply-form mt-6">
        <textarea
          value={replyMessage}
          onChange={(e) => setReplyMessage(e.target.value)}
          placeholder="Type your reply..."
          className="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
          rows="4"
        ></textarea>
        <button
          type="submit"
          className="mt-4 bg-blue-500 text-white py-2 px-6 rounded-lg hover:bg-blue-600 transition-colors"
        >
          Send Reply
        </button>
      </form>
    </div>
  );
};

export default TicketDetails;
