import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import Layout from "./BaseFiles/Layout";
import Dashboard from "./Admin/Dashboard";
import ProjectList from "./project/ProjectList";
import TicketList from "./ticket/TicketList";
import HomeLayout from "./UIUX/Home/HomeLayout";
import Home from "./UIUX/Home/Home";
import GenerateTicket from "./user/pages/GenerateTicket";
import ContactUs from "./UIUX/Component/ContactUs";
import ProjectDetail from "./project/ProjectDetail";
import About from "./UIUX/Component/About";
import Service from "./UIUX/Component/Service";
import AiService from "./UIUX/Component/AiService";
import SeoMarketing from "./UIUX/Component/SeoMarketing";
import Wallet from "./UIUX/Component/Wallet";
import AddProject from "./project/AddProject";
import AddBlog from "./blog/AddBlog";
import UserLogin from "./user/pages/UserLogin";
import { Dashboard as UserDashboard } from "./user/components/Dashboard";
import Career from "./FooterPages/Career";
import Faqs from "./FooterPages/Faqs";
import Works from "./FooterPages/Works";
import Features from "./FooterPages/Features";
import Policy from "././FooterPages/Policy";
import Conditions from "./FooterPages/Conditions";
import PrivateRoutes from "./BaseFiles/PrivateRoutes";
import Blogs from "./blogs/Blogs";
import BlogOverview from "./blogs/BlogOverview";
import CreateBlog from "./blogs/CreateBlog";
import TicketDetails from "./ticket/TicketDetails";
import NotFound from "./pages/NotFound";
import ScrollToTop from "./UI/ScrollToTop";
function App() {
  return (
    <Router>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/admin/login" element={<Login />} />
          <Route element={<PrivateRoutes />}>
            <Route element={<Layout />}>
              <Route path="/admin/dashboard" element={<Dashboard />} />
              <Route path="/project/list" element={<ProjectList />} />
              <Route path="/ticket/list" element={<TicketList />} />
              <Route path="/ticket/:ticket_no" element={<TicketDetails />} />
              <Route
                path="/project/detail/:project_id"
                element={<ProjectDetail />}
              />
              <Route path="/blog/add" element={<AddBlog />} />
              <Route path="/add/project" element={<AddProject />} />
              <Route path="/blogs" element={<Blogs />} />
              <Route path="/blogs/create" element={<CreateBlog />} />
              <Route path="/blogs/view/:id" element={<BlogOverview />} />
            </Route>
          </Route>

          <Route
            path="/generate/ticket"
            element={
              <HomeLayout>
                <GenerateTicket />
              </HomeLayout>
            }
          />
          <Route
            path="/Contact/us"
            element={
              <HomeLayout>
                <ContactUs />
              </HomeLayout>
            }
          />
          <Route
            path="/about"
            element={
              <HomeLayout>
                <About />
              </HomeLayout>
            }
          />

          <Route
            path="/service/:index"
            element={
              <HomeLayout>
                <Service />
              </HomeLayout>
            }
          />
          <Route
            path="/AiService/:index"
            element={
              <HomeLayout>
                <AiService />
              </HomeLayout>
            }
          />
          <Route
            path="/SeoMarketing/:index"
            element={
              <HomeLayout>
                <SeoMarketing />
              </HomeLayout>
            }
          />
          <Route
            path="/wallet/:index"
            element={
              <HomeLayout>
                <Wallet />
              </HomeLayout>
            }
          />
          <Route
            path="/career"
            element={
              <HomeLayout>
                <Career />
              </HomeLayout>
            }
          />
          <Route
            path="/faqs"
            element={
              <HomeLayout>
                <Faqs />
              </HomeLayout>
            }
          />
          <Route
            path="/policy"
            element={
              <HomeLayout>
                <Policy />
              </HomeLayout>
            }
          />
          <Route
            path="/conditions"
            element={
              <HomeLayout>
                <Conditions />
              </HomeLayout>
            }
          />
          <Route
            path="/features"
            element={
              <HomeLayout>
                <Features />
              </HomeLayout>
            }
          />
          <Route
            path="/works"
            element={
              <HomeLayout>
                <Works />
              </HomeLayout>
            }
          />
          <Route path="*" element={<NotFound />} />
          <Route path="/login" element={<UserLogin />} />
          <Route element={<UserDashboard />}>
            <Route path="/user/dashboard/ticket" element={<GenerateTicket />} />
            <Route path="/user/dashboard" element={<GenerateTicket />} />
          </Route>
        </Routes>
      </ScrollToTop>
    </Router>
  );
}

export default App;
